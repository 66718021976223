/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-scroll";
import Scrollspy from "react-scrollspy";
import FlashMessage from "../../components/FlashMessage";
import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import Analytics from "../../utils/analytics";
import {
    RestaurantClose,
    RestaurantInfo,
    VariationModal,
} from "../../components/Modals";
import {
    ItemCard,
    ItemList,
    MRestaurantCart,
    RestaurantCart,
    RestaurantHeader,
} from "../../components/RestaurantDetailComponent";
import UserContext from "../../context/User";
import { useRestaurants } from "../../hooks";
import { DAYS } from "../../utils/constantValues";
import useStyles from "./styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import qrCodeImage from "../../assets/images/qrcode.png";

function Schedule() {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
    const classes = useStyles();
    const { state } = useLocation();
    const query = useParams();
    const [isClose, setIsClose] = useState(false);
    const [mainError, setMainError] = useState({});
    const [addonData, setAddonData] = useState(null);
    const [menu, setMenu] = useState({}); // State to store the organized data
    const dates = Object.keys(menu || {});
    const [selectedDate, setSelectedDate] = useState(dates && dates.length > 0 ? dates[0] : null);
    const handleDateClick = (date) => {
        setSelectedDate(date);
    };
    const [reviewModal, setReviewModal] = useState(false);
    const [variationModal, setVariationModal] = useState(false);
    const {
        restaurant: restaurantCart,
        setCartRestaurant,
        checkItemCart,
        addCartItem,
        addQuantity,
        cart,
        clearCart,
        isLoggedIn,
    } = useContext(UserContext);

    const { data, loading, error } = useRestaurants();

    // useEffect to handle async data processing
    useEffect(() => {
        // Define an async function to handle data processing
        const fetchData = async () => {
            if (!data) return;

            // Process data when available
            const allCategories =
                data.restaurants?.flatMap(restaurant =>
                    restaurant.categories.map(category => ({
                        ...category,
                        restaurantId: restaurant._id,
                        restaurantName: restaurant.name,
                        deliveryCharges: restaurant.deliveryCharges,
                    }))
                ) || [];

           

            // Filter out categories without foods
            const allDeals = allCategories.filter(
                category => category.foods && category.foods.length > 0
            );
           

            // Map categories to add an index or perform any operation
            const indexedCategories = allDeals.map((category, index) => ({
                ...category,
                index,
            }));

            // Organize the data using the helper function
            const organizedData = await organizeData(indexedCategories);
            console.log('Organized Menu:', organizedData);

            const sorted = await sortAndFilterData(organizedData);
            console.log('Sorted Menu:', sorted);
            // Update state with organized data
            setMenu(sorted);
        };

        // Call the async function when the component is ready
        fetchData();
    }, [data]);

    function sortAndFilterData(organized) {
        // Get the current date and time
        const currentTime = new Date();
      
        // Set currentTime to midnight (00:00:00.000) to compare only the date
        currentTime.setHours(0, 0, 0, 0);
      
        // Filter the dates to keep only the current or future dates
        let filteredDates = Object.entries(organized).filter(([dateKey]) => {
          // Convert dateKey to a Date object and set to midnight
          const dateObject = new Date(dateKey);
          dateObject.setHours(0, 0, 0, 0);
          dateObject.setDate(dateObject.getDate()+1)
      
          return dateObject >= currentTime;
        });
      
        //console.log("filtered dates "+filteredDates)
        // Sort the dates in chronological order
        filteredDates = filteredDates.sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));
        //console.log("sorted dates "+filteredDates)
        // Iterate over each date and sort times, removing past times for the current day
        const result = filteredDates.reduce((acc, [dateKey, times]) => {
          // Convert the dateKey to a Date object
          const dateObject = new Date(dateKey);
      
          // Sort the times within the date from earliest to latest
          const sortedTimes = Object.entries(times).sort(([timeA], [timeB]) => {
            const [hoursA, minutesA] = timeA.split(':').map(Number);
            const [hoursB, minutesB] = timeB.split(':').map(Number);
            return hoursA - hoursB || minutesA - minutesB;
          });
      
          // Add sorted and filtered times back to the result if not empty
          if (sortedTimes.length > 0) {
            acc[dateKey] = Object.fromEntries(sortedTimes);
          }
          return acc;
        }, {});
      
        return Object.fromEntries(Object.entries(result).slice(0, 5));
      }


    function organizeData(categories) {
        const result = {};
      
        // Iterate over each category
        categories.forEach((category) => {
          const { restaurantId, restaurantName, deliveryCharges } = category;
      
          // Iterate over each food in the category
          category.foods.forEach((food) => {
            // Iterate over each variation of the food
            food.variations.forEach((variation) => {
              // Iterate over each date in the variation
              variation.date?.forEach((date) => {
                // Check if the date key exists in the result, if not, initialize it as an object
                if (!result[date]) {
                  result[date] = {};
                }
      
                // Create a map to hold times and foods
                const timeMap = {};
      
                // Iterate over each time in the variation
                variation.time.forEach((time) => {
                  // Initialize the array for the time if it doesn't exist
                  if (!timeMap[time]) {
                    timeMap[time] = [];
                  }
                  // Push the food item to the corresponding time array
                  timeMap[time].push({
                    ...food,
                    restaurantId,
                    restaurantName,
                    deliveryCharges,
                  });
                });
      
                // Merge the timeMap into result[date] directly
                Object.keys(timeMap).forEach((time) => {
                  // If the time key doesn't exist in the result[date], initialize it
                  if (!result[date][time]) {
                    result[date][time] = [];
                  }
                  // Merge the foods into the time key in result[date]
                  result[date][time] = result[date][time].concat(timeMap[time]);
                });
              });
            });
          });
        });
      
        return result;
      }



    const addFoodToCart = async (food, date, time) => {
        //commented because orders are allowed to be placed at any time. Add logic to prevent ordering past deadline later. 
        // if (!restaurantInfo.isAvailable || !isOpen()) {
        //   toggleCloseModal();
        //   return;
        // }
        // no need to check if orders are from different restaurants
        // if (!restaurantCart || food.restaurant === restaurantCart) {
        //   await addToCart(food, food.restaurant !== restaurantCart);
        // }
        await addToCart(food, 0, date, time);
    };

    const addToCart = async (food, clearFlag, date, time) => {

        if (
            food.variations.length === 1 &&
            food.variations[0].addons.length === 0
        ) {
            const result = checkItemCart(food._id, date, time);
            if (result.exist) await addQuantity(result.key);
            else
                await addCartItem(food._id, food.restaurantId,date, time, food.variations[0]._id, 1, [], clearFlag);
        } else {
            //not using the following
            if (clearFlag) await clearCart();
            console.log("this shouldn't have been ran");
            setAddonData({
                food,
                addons: food.restaurant.addons,
                options: food.restaurant.options,
                restaurant: food.restaurant._id,
                image: food.restaurant?.image,
            });
            toggleVariationModal();
        }
        await Analytics.track(Analytics.events.ADD_TO_CART, {
            title: food.title,
            restaurantName: food.restaurantName,
            variations: food.variations,
        });
    };


    const toggleVariationModal = useCallback(() => {
        setVariationModal((prev) => !prev);
    }, []);

    const showMessage = useCallback((messageObj) => {
        setMainError(messageObj);
    }, []);
    const toggleSnackbar = useCallback(() => {
        setMainError({});
    }, []);

    if (loading || Object.keys(menu).length === 0) {
        return <p>Loading...</p>; // Or a spinner or any loading indicator
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }


    const JoinGroup = () => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            padding={2}
          >
            {/* Title */}
            <Typography variant="h5" gutterBottom>
              Join Our Discord or WeChat Group Chat! Receive update as your order is dropped off at each residence!
            </Typography>
      
            {/* QR Code Image */}
            <Box my={2}>
              <img
                src={qrCodeImage} // Replace with your QR code image URL
                alt="QR Code"
                style={{ width: '200px', height: '200px' }}
              />
            </Box>
      
            {/* Discord Join Link */}
            <Button
              href="https://discord.gg/CQnv6xmu"
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="primary"
            >
              Join Our Discord
            </Button>
          </Box>
        );
      };

    // Get times for the selected date
    const timesForDate = selectedDate ? menu[selectedDate] : (dates ? (menu[dates[0]]) : null);

    return (
        <>
            <FlashMessage
                open={Boolean(mainError.type)}
                severity={mainError.type}
                alertMessage={mainError.message}
                handleClose={toggleSnackbar}
            />
            <div
                style={{
                    backgroundColor: theme.palette.grey[200],
                    scrollBehavior: "smooth",
                    marginTop: '20px',
                }}
            >
                <Grid container>
                    {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
                </Grid>
                {/* <Subheader /> */}
                <Grid
                    container
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                    className={classes.bg}
                >
                    {!isTablet && <RestaurantCart showMessage={showMessage} />}
                    <Grid item lg={9} xs={12}>
                        <Container
                            maxWidth="xl"
                            style={{
                                marginLeft: "0px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                marginTop: "44px",
                                borderBottomLeftRadius: 50,
                                borderBottomRightRadius: 50,
                            }}
                        >
                        </Container>
                        <Box p={3}>
                            <Container
                                maxWidth="xl"
                                style={{
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                }}
                            >
                                {JoinGroup()}
                                <ButtonGroup variant="contained" color="primary" aria-label="date selection">
                                    {dates.map((date) => (
                                        <Button
                                            key={date}
                                            onClick={() => handleDateClick(date)}
                                        >
                                            {date.split('T')[0]}
                                        </Button>
                                    ))}
                                </ButtonGroup>

                                <Box>
                                    {Object.entries(timesForDate).map(([time, foods], index) => (
                                        
                                        <Box key={index} mb={3}>

                                            {/* Map through the array of foods for this particular time */}
                                            <ItemList
                                                time={time}
                                                date={selectedDate? selectedDate : dates[0]}
                                                foods={foods}
                                                onPress={addFoodToCart}
                                            />
                                        </Box>
                                    ))}
                                </Box>

                                {/* {allCategories.map((item, index) => (
                      <section key={`SECTION_${index}`} id={item._id}>
                        <ItemCard
                          {...item}
                          onPress={addFoodToCart}
                          restaurant={{
                            restaurant: item.restaurantId,
                            restaurantName: item.restaurantName,
                            deliveryCharges: item.deliveryCharges,
                          }}
                        />
                      </section>
                    ))} */}
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    className={classes.footerContainer}
                    style={{ background: "transparent" }}
                >
                    <Box className={classes.footerWrapper}>
                        <Footer />
                    </Box>
                </Box>

                <VariationModal
                    isVisible={variationModal}
                    toggleModal={toggleVariationModal}
                    data={addonData}
                />
                {isTablet && cart.length && (
                    <MRestaurantCart showMessage={showMessage} />
                )}
            </div>
        </>
    );
}

export default Schedule;


