/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import ConfigurationContext from "../../src/context/Configuration";

const ConfigurableValues = () => {
  const configuration = useContext(ConfigurationContext);
  const SERVER_URL = "https://api.yumme.ca/";
  const WS_SERVER_URL = "wss://api.yumme.ca/";
  // const SERVER_URL = 'http://192.168.0.107:8001/'
  // const WS_SERVER_URL = 'ws://192.168.0.107:8001/'
  const GOOGLE_CLIENT_ID = configuration.webClientID;
  const STRIPE_PUBLIC_KEY = configuration.publishableKey;
  const PAYPAL_KEY = configuration.clientId;
  const GOOGLE_MAPS_KEY = "AIzaSyDKrEByOFwaoPwdjTbH7zpd5xyx1ridL0o";
  const AMPLITUDE_API_KEY = configuration.webAmplitudeApiKey;
  const LIBRARIES = "places,drawing,geometry,localContext,visualization".split(
    ","
  );
  const COLORS = {
    GOOGLE: configuration.googleColor,
  };
  const SENTRY_DSN = configuration.webSentryUrl;
  const SKIP_EMAIL_VERIFICATION = configuration.skipEmailVerification;
  const SKIP_MOBILE_VERIFICATION = configuration.skipMobileVerification;
  const VAPID_KEY = 'BAGse_K6e-LlJd0455UT6hxrSw9G225BAgmao-60Lb4zhiHiBVScl8uYxVa7F0LKAMXahoSRsWXgHNEl6qpakxw';

  return {
    SERVER_URL,
    WS_SERVER_URL,
    GOOGLE_CLIENT_ID,
    COLORS,
    PAYPAL_KEY,
    STRIPE_PUBLIC_KEY,
    GOOGLE_MAPS_KEY,
    AMPLITUDE_API_KEY,
    LIBRARIES,
    SENTRY_DSN,
    SKIP_EMAIL_VERIFICATION,
    SKIP_MOBILE_VERIFICATION,
    VAPID_KEY
  };
};

export default ConfigurableValues;
