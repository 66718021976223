/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { myOrders, placeMultipleOrders } from "../../apollo/server";
import CodIcon from "../../assets/icons/CodIcon";
import RiderImage from "../../assets/images/rider.png";
import MarkerImage from "../../assets/images/marker.png";
import {
  CartItemCard,
  DeliveryCard,
  PaymentCard,
  PersonalCard,
  OrderOption,
} from "../../components/Checkout";
import CloseIcon from "@mui/icons-material/Close";

import FlashMessage from "../../components/FlashMessage";
import Footer from "../../components/Footer/Footer";
import { Header } from "../../components/Header";
import { RestaurantClose } from "../../components/Modals";
import ConfigurationContext from "../../context/Configuration";

import UserContext from "../../context/User";
import { useRestaurant } from "../../hooks";
import { DAYS } from "../../utils/constantValues";
import { paypalCurrencies, stripeCurrencies } from "../../utils/currencies";
import { calculateDistance, calculateAmount } from "../../utils/customFunction";
import useStyle from "./styles";

import Analytics from "../../utils/analytics";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { mapStyles } from "../OrderDetail/mapStyles";
import RestMarker from "../../assets/images/rest-map-2.png";
import NearMeIcon from "@mui/icons-material/NearMe";
import clsx from "clsx";


import { useTranslation } from "react-i18next";

import moment from "moment";

const PLACEORDERS = gql`
  ${placeMultipleOrders}
`;
const ORDERS = gql`
  ${myOrders}
`;

const PAYMENT = {
  id: 2,
  payment: "COD",
  label: "Cash",
  icon: <CodIcon />,
};

function Checkout() {
  const { t } = useTranslation();
  const classes = useStyle();
  const navigate = useNavigate();
  const [isClose, setIsClose] = useState(false);
  const [mainError, setMainError] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const configuration = useContext(ConfigurationContext);
  const [addressModal, setAddressModal] = useState(false);
  const [orderOptionModal, setOrderOptionModal] = useState(false);
  const fetchRef = useRef(false);

  const {
    profile,
    clearCart,
    restaurant: cartRestaurant,
    cart,
    cartCount,
    addQuantity,
    removeQuantity,
  } = useContext(UserContext);

  const [ deliveryPoint, setDeliveryPoint] = useState(null);
  const theme = useTheme();
  const [minimumOrder, setMinimumOrder] = useState("");
  const [selectedTip, setSelectedTip] = useState();
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT);
  const [taxValue, setTaxValue] = useState();

  const [coupon, setCoupon] = useState({});
  const [selectedDate, handleDateChange] = useState(new Date());
  const [isPickUp, setIsPickUp] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0.3);

  //const { loading, data, error } = useRestaurant(cartRestaurant);
  const extraSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [mutateOrder, { loading: loadingOrderMutation }] = useMutation(
    PLACEORDERS,
    {
      onCompleted,
      onError,
      // update,
    }
  );



  const toggleAdressModal = useCallback(() => {
    setAddressModal((prev) => !prev);
  }, []);




  const toggleCloseModal = useCallback(() => {
    setIsClose((prev) => !prev);
  }, []);

  //const restaurantData = data?.restaurant ?? null;

  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);

  const toggleSnackbar = useCallback(() => {
    setMainError({});
  }, []);

  // if (loading || loadingData) {
  //   return (
  //     <Grid container>
  //       <Header />
  //       <Box className={classes.spinnerContainer}>
  //         <CircularProgress color="primary" size={48} />
  //       </Box>
  //     </Grid>
  //   );
  // }

  // if (error) {
  //   return (
  //     <Grid container>
  //       <Header />
  //       <Box className={classes.spinnerContainer}>
  //         <Typography>Unable to fetch data</Typography>
  //       </Box>
  //     </Grid>
  //   );
  // }

  // function update(cache, { data: { placeOrder } }) {
  //   console.log("update");
  //   if (placeOrder && placeOrder.paymentMethod === "COD") {
  //     const data = cache.readQuery({ query: ORDERS });
  //     if (data) {
  //       cache.writeQuery({
  //         query: ORDERS,
  //         data: { orders: [placeOrder, ...data.orders] },
  //       });
  //     }
  //   }
  // }

  function onError(error) {
    console.log("Check-out Error", error);
    showMessage({
      type: "error",
      message: error.message,
    });
  }

  function calculateTip() {
    if (selectedTip) {
      let total = 0;
      const delivery = isPickUp ? 0 : deliveryCharges;
      total += +calculatePrice(delivery, true);
      total += +taxCalculation();
      const tipPercentage = (
        (total / 100) *
        parseFloat(selectedTip).toFixed(2)
      ).toFixed(2);
      return tipPercentage;
    } else {
      return 0;
    }
  }

  function taxCalculation() {
    const tax = 5;
    if (tax === 0) {
      return tax.toFixed(2);
    }
    const delivery = isPickUp ? 0 : deliveryCharges;
    const amount = +calculatePrice(delivery, true);
    const taxAmount = ((amount / 100) * tax).toFixed(2);
    console.log("tax:", {taxAmount, deliveryCharges, tax, amount})
    return taxAmount;
  }
  async function onCompleted(data) {
    // await Analytics.track(Analytics.events.ORDER_PLACED, {
    //   userId: data.placeOrder.user._id,
    //   name: data.placeOrder.user.name,
    //   email: data.placeOrder.user.email,
    //   phoneNumber: data.placeOrder.user.phone,
    //   orderId: data.placeOrder.orderId,
    //   restaurantName: data.placeOrder.restaurant.name,
    //   restaurantAddress: data.placeOrder.restaurant.address,
    //   orderItems: data.placeOrder.items,
    //   orderPaymentMethod: data.placeOrder.paymentMethod,
    //   orderAmount: data.placeOrder.orderAmount,
    //   orderPaidAmount: data.placeOrder.paidAmount,
    //   tipping: data.placeOrder.tipping,
    //   orderStatus: data.placeOrder.orderStatus,
    //   orderDate: data.placeOrder.orderDate,
    // });
    console.log("orders successfully placed; yet to process paypal payments. The payment id is "+data.placeMultipleOrders._id)
    // if (paymentMethod.payment === "COD") {
    //   await clearCart();
    //   navigate(`/order-detail/${data.placeOrder._id}`, { replace: true });
    // } else if (paymentMethod.payment === "PAYPAL") {
    //   navigate(`/paypal?id=${data.placeOrder._id}`, { replace: true });
    // } else if (paymentMethod.payment === "STRIPE") {
    //   navigate(`/stripe?id=${data.placeOrder._id}`, { replace: true });
    // }
    navigate(`/paypal?id=${data.placeMultipleOrders._id}`, { replace: true });
  }

  function calculatePrice(delivery = 0, withDiscount) {
    let itemTotal = 0;
    cart.forEach((cartItem) => {
      // console.log(cartItem)
      itemTotal += cartItem.price * cartItem.quantity;
    });
    if (withDiscount && coupon && coupon.discount) {
      itemTotal = itemTotal - (coupon.discount / 100) * itemTotal;
    }
    const deliveryAmount = delivery > 0 ? deliveryCharges : 0;
    // console.log("price:", {itemTotal, deliveryAmount})
    return (itemTotal + deliveryAmount).toFixed(2);
  }

  function calculateTotal() {
    let total = 0;
    const delivery = isPickUp ? 0 : deliveryCharges;
    total += +calculatePrice(delivery, true);
    total += +taxCalculation();
    total += +calculateTip();
    return parseFloat(total).toFixed(2);
  }

  function transformOrder(cartData) {
    return cartData.map((food) => {
      return {
        food: food._id,
        quantity: food.quantity,
        variation: food.variation._id,
        addons: food.addons
          ? food.addons.map(({ _id, options }) => ({
              _id,
              options: options.map(({ _id }) => _id),
            }))
          : [],
        specialInstructions: food.specialInstructions,
      };
    });
  }

  function checkPaymentMethod(currency) {
    if (paymentMethod.payment === "STRIPE") {
      return stripeCurrencies.find((val) => val.currency === currency);
    }
    if (paymentMethod.payment === "PAYPAL") {
      return paypalCurrencies.find((val) => val.currency === currency);
    }
    return true;
  }


  async function onPayment() {
    // Check if the payment method is supported
    if (!checkPaymentMethod(configuration.currency)) {
      showMessage({
        type: "warning",
        message: "Payment not supported",
      });
      return;
    }
  
    // Group cart items by restaurantId, date, and time
    const groupedItems = groupCartItemsByFields(cart);
  
    // Prepare an array to hold all the orders
    const orders = [];
  
    // Iterate over each group and prepare the order data
    for (const groupKey in groupedItems) {
      const group = groupedItems[groupKey];
      
      // Extract common fields from the first item in the group
      const { restaurantId, date, time } = group[0];
  
      // Prepare the order items and other order details
      const items = transformOrder(group); // Only transform the current group of items
  
      // Create the order object for each group
      const order = {
        restaurant: restaurantId,
        orderInput: items,
        paymentMethod: paymentMethod.payment,
        couponCode: coupon ? coupon.title : null,
        tipping: +calculateTip(),
        taxationAmount: +taxCalculation(),
        address: {
          label: deliveryPoint?.label,
          details: deliveryPoint?.detail,
          latitude: String(deliveryPoint?.latitude),
          longitude: String(deliveryPoint?.longitude),
          deliveryAddress: deliveryPoint?.deliveryAddress,
        },
        orderDate: date,
        date: date,
        time: time,
        isPickedUp: isPickUp,
        deliveryCharges: isPickUp ? 0 : deliveryCharges,
      };
  
      // Add the order to the orders array
      orders.push(order);
    }
  
    // Call the new mutation to place multiple orders
    try {
      await mutateOrder({
        variables: {
          orders, // Pass the array of orders
        },
      });
  
      console.log("All orders placed successfully!");
    } catch (error) {
      console.error("Error placing multiple orders:", error.message);
    }
  }
  
  // Helper function to group cart items by restaurantId, date, and time
  function groupCartItemsByFields(cart) {
    const grouped = {};
  
    // Iterate over each cart item
    cart.forEach((item) => {
      // Create a unique key for each group based on restaurantId, date, and time
      const groupKey = `${item.restaurantId}_${item.date}_${item.time}`;
  
      // Initialize the group if it doesn't exist
      if (!grouped[groupKey]) {
        grouped[groupKey] = [];
      }
  
      // Add the current item to the group
      grouped[groupKey].push(item);
    });
  
    return grouped;
  }
  


  function validateOrder() {
    // if (!data.restaurant.isAvailable) {
    //   toggleCloseModal();
    //   return;
    // }
    if (!cart.length) {
      showMessage({
        type: "error",
        message: "Cart is Empty.",
      });
      return false;
    }
    const delivery = isPickUp ? 0 : deliveryCharges;
    if (calculatePrice(delivery, true) < minimumOrder) {
      showMessage({
        type: "warning",
        message: `The minimum amount of (${configuration.currencySymbol} ${minimumOrder}) for your order has not been reached.`,
      });
      return false;
    }
    if (!deliveryPoint) {
      showMessage({
        alive: true,
        type: "Warning",
        message: "Select your address.",
      });
      return false;
    }
    if (!paymentMethod) {
      showMessage({
        type: "warning",
        message: "Set payment method before checkout",
      });
      return false;
    }
    if (profile.phone.length < 1) {
      showMessage({
        alive: true,
        type: "Error",
        message: t("phoneNumMissing"),
      });

      setTimeout(() => {
        navigate("/phone-number");
      }, 1000);

      return false;
    }
    if (!profile.phoneIsVerified) {
      showMessage({
        alive: true,
        type: "Error",
        message: "Phone Number is not verified",
      });

      setTimeout(() => {
        navigate("/phone-number");
      }, 1000);

      return false;
    }
    return true;
  }

  if (!configuration.deliveryLocations) {
    // You can return a loading indicator or a placeholder here
    return <div>Loading...</div>;
  }
  // console.log("isPickUp", isPickUp, selectedDate);
  return (
    <Grid container className={classes.root}>
      <FlashMessage
        open={Boolean(mainError.type)}
        severity={mainError.type}
        alertMessage={mainError.message}
        handleClose={toggleSnackbar}
        alive={mainError.alive || false}
      />

      <Header />
      <Grid
        container
        item
        className={classes.mainContainer}
        justifyContent="center"
      >
        <Grid container item>
          <Grid item xs={12} className={classes.topContainer}>
            
          </Grid>
        </Grid>
        <Container maxWidth="md" className={classes.containerCard}>
          <Box
            className={classes.headerBar}
            display="flex"
            alignItems={"center"}
          >
            {/* <Box display="flex" alignItems="center" justifyContent="center">
              <img src={RiderImage} alt="rider" />
            </Box> */}
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              style={{
                marginLeft: "20px",
              }}
            >
              <Typography
                style={{
                  ...theme.typography.body1,
                  color: theme.palette.common.black,
                  fontSize: "1.275rem",
                  fontWeight: 600,
                }}
              >
                {t("deliveryTime")}
              </Typography>
              <Typography
                style={{
                  ...theme.typography.body1,
                  color: theme.palette.grey[600],
                  fontSize: "0.775rem",
                  fontWeight: 600,
                }}
              >
                {moment().format("DD-MM-YYYY")} | {moment().format("LT")}
              </Typography>
              <Box display="flex" mt={2} alignItems="center">
                <Typography
                  style={{
                    ...theme.typography.body1,
                    color: theme.palette.common.white,
                    fontSize: "0.875rem",
                    fontWeight: 600,
                  }}
                >
                  {isPickUp ? t("pickUp") : t("delivery")}
                </Typography>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: theme.spacing(1),
                    backgroundColor: "black",
                    borderRadius: theme.spacing(1.5),
                  }}
                  onClick={() => setOrderOptionModal((prev) => !prev)}
                >
                  <Typography
                    style={{
                      color: theme.palette.common.white,
                      fontSize: "0.775rem",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {t("change")}
                  </Typography>
                </Button>
                <OrderOption
                  selectedDate={selectedDate}
                  handleDateChange={handleDateChange}
                  setIsPickUp={setIsPickUp}
                  orderOptionModal={orderOptionModal}
                  setOrderOptionModal={setOrderOptionModal}
                  isPickUp={isPickUp}
                />
              </Box>
            </Box>
          </Box>
          <Grid container spacing={2} sx={{ mt: 2, mb: 25 }}>
            <Grid item xs={12} sm={6}>
              <CartItemCard
                setSelectedTip={setSelectedTip}
                selectedTip={selectedTip}
                setTaxValue={setTaxValue}
                setCoupon={setCoupon}
                restaurantData={null}
                setFlashMessage={showMessage}
                calculateTotal={calculateTotal}
                calculatePrice={calculatePrice}
                taxCalculation={taxCalculation}
                calculateTip={calculateTip}
                isPickUp={isPickUp}
                deliveryCharges={deliveryCharges}
                addQuantity={addQuantity}
                removeQuantity={removeQuantity}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {console.log('setDeliveryPoint:', setDeliveryPoint)}
              <PersonalCard
                locations={configuration.deliveryLocations}
                setDeliveryPoint={setDeliveryPoint}
              />
              <PaymentCard
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                validateOrder={validateOrder}
                onPayment={onPayment}
                loading={loadingOrderMutation}
                calculateTotal={calculateTotal}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Box className={classes.footerContainer}>
        <Box className={classes.footerWrapper}>
          <Footer />
        </Box>
      </Box>
    </Grid>
  );
}

export default Checkout;
