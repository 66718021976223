/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useContext, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import Analytics from "../../utils/analytics";
import useStyle from "./styles";

function Terms() {
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_TERMS);
  }, []);

  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);

  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography variant="h4" color="textPrimary" align="center" className={classes.title}>
            TERMS AND CONDITIONS | YUMME
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <ol className={classes.boldText}>
              <Typography variant="subtitle2" className={classes.MV3}>
                Published: 2024
              </Typography>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Contractual Relationship
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  These Terms of Use (“<b>Terms</b>”) govern your access to and use of the websites and mobile
                  applications provided by Yumme (collectively, the “<b>Services</b>”). By accessing and using the
                  Services, you agree that you have read, understood, and accepted these Terms, including any additional
                  terms and conditions and policies referenced herein or available by hyperlink. If you do not agree to
                  these Terms, you may not access or use the Services.
                </Typography>
                <Typography variant="subtitle2">
                  Yumme reserves the right to change or modify these Terms at any time. You will be deemed to have
                  agreed to the amended Terms by your continued use of the Services following the date on which the
                  amended Terms are posted.
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  The Services
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  Yumme provides a technology platform that enables users to schedule bulk food deliveries from
                  independent third-party providers (“<b>Third Party Providers</b>”). Unless otherwise agreed in a
                  separate written agreement with you, the Services are made available solely for your personal,
                  non-commercial use.
                </Typography>
                <Typography variant="subtitle2">
                  Yumme does not provide food delivery services or function as a delivery carrier. All such delivery
                  services are provided by independent third-party contractors who are not employed by Yumme or any of
                  its affiliates.
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Your Use of the Services
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  To use most aspects of the Services, you must register for and maintain an active personal user
                  Services account (“<b>Account</b>”). You must be at least 18 years of age, or the age of legal
                  majority in your jurisdiction (if different than 18), to obtain an Account. Account registration
                  requires you to submit certain personal information, such as your name, address, mobile phone number,
                  and age, as well as at least one valid payment method (either a credit card or accepted payment
                  partner).
                </Typography>
                <Typography variant="subtitle2">
                  You agree to maintain accurate, complete, and up-to-date information in your Account. Your failure to
                  maintain accurate, complete, and up-to-date Account information, including having an invalid or
                  expired payment method on file, may result in your inability to access and use the Services or Yumme’s
                  termination of these Terms with you.
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Payment
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  You understand that use of the Services may result in charges for the services or goods you receive
                  from a Third Party Provider (“<b>Charges</b>”). After you have received services or goods obtained
                  through your use of the Service, Yumme will facilitate your payment of the applicable Charges on
                  behalf of the Third Party Provider. Charges paid by you are final and non-refundable, unless otherwise
                  determined by Yumme.
                </Typography>
                <Typography variant="subtitle2">
                  All Charges are due immediately, and payment will be facilitated using the preferred payment method
                  designated in your Account. Yumme reserves the right to establish, remove, and/or revise Charges for
                  any or all services or goods obtained through the use of the Services at any time in Yumme’s sole
                  discretion.
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Disclaimers; Limitation of Liability; Indemnity
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  <b>DISCLAIMER.</b> THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." YUMME DISCLAIMS ALL
                  REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS,
                  INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                  NON-INFRINGEMENT.
                </Typography>
                <Typography variant="subtitle2">
                  <b>LIMITATION OF LIABILITY.</b> YUMME SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
                  EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR
                  PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES,
                  EVEN IF YUMME HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Governing Law; Arbitration
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  These Terms shall be exclusively governed by and construed in accordance with the laws of [Your
                  Jurisdiction]. Any dispute arising out of or related to these Terms will be subject to binding
                  arbitration.
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Amendments
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  Yumme may amend these Terms at any time in its sole discretion. The amended Terms shall be effective
                  immediately upon posting, and you agree to the new Terms by continued use of the Services. It is your
                  responsibility to check the Terms regularly. If you do not agree with the amended Terms, whether in
                  whole or in part, you must stop using the Services immediately.
                </Typography>
              </li>

              <li>
                <Typography variant="subtitle2" className={classes.boldText}>
                  Contact Us
                </Typography>
                <Typography variant="subtitle2" className={classes.MV3}>
                  If you wish to contact us regarding any questions or comments you may have, please send an email to
                  our customer support email or via our in-app customer support chat feature.
                </Typography>
              </li>
            </ol>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default React.memo(Terms);
