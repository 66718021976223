import {
  Box,
  Menu,
  MenuItem,
  useTheme,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // "More" icon
import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import useStyle from "./styles";
import { ReactComponent as PersonIcon } from "../../assets/icons/user.svg";
import { ReactComponent as LocalMallIcon } from "../../assets/icons/cart.svg";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

function DHeader({
  navitems,
  title,
  name,
  close,
  favLength = 0,
  cartCount = 0,
  mobile,
}) {
  const theme = useTheme();
  const classes = useStyle();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Detect small screens

  // State for Profile Menu
  const [anchorEl, setAnchorEl] = useState(null);

  // State for "More" menu
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);

  // Handle opening Profile Menu
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle closing Profile Menu
  const handleProfileMenuClose = (route) => {
    close(route);
  };

  // Handle opening "More" menu
  const handleMoreClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  // Handle closing "More" menu
  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  const isProfileMenuOpen = Boolean(anchorEl);
  const isMoreMenuOpen = Boolean(moreAnchorEl);

  return (
    <AppBar
      elevation={0}
      position="fixed"
      style={{
        background: "transparent",
      }}
      className={classes.root}
    >
      <Toolbar className={classes.toolbar}>
        {/* Logo */}
        <RouterLink
          to={
            location.pathname === "/checkout"
              ? "/restaurant-list"
              : "/restaurant-list"
          }
          className={classes.linkDecoration}
        >
          <Logo height={50} width={50} />
          <Typography
            variant="h6"
            color={theme.palette.common.black}
            className={classes.font700}
            style={{ marginLeft: "8px" }}
          >
            {title}
          </Typography>
        </RouterLink>

        <Box className={classes.flex}>
          {/* Profile Icon */}
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <PersonIcon className={classes.icon} />
            {!mobile && (
              <Typography
                variant="button"
                color="textSecondary"
                noWrap
                className={`${classes.ml} ${classes.font700}`}
                style={{ maxWidth: 70 }}
              >
                {name}
              </Typography>
            )}
            {isProfileMenuOpen ? (
              <ExpandLessIcon color="primary" className={classes.icon} />
            ) : (
              <ExpandMoreIcon color="primary" className={classes.icon} />
            )}
          </Button>

          {/* Profile Menu */}
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={isProfileMenuOpen}
            onClose={handleProfileMenuClose}
            getcontentanchorel={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {navitems.map((item, index) => (
              <RouterLink
                key={index}
                to={item.link}
                style={{ textDecoration: "none", display: "block" }} // Ensure the entire block is clickable and no text decoration
              >
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => handleProfileMenuClose(item.title)}
                >
                  <Typography color="textSecondary" variant="body2">
                    {item.title}
                  </Typography>
                </MenuItem>
              </RouterLink>
            ))}
          </Menu>

          {/* Conditionally render More icon or buttons based on screen width */}
          {isSmallScreen ? (
            // Display "More" button on small screens
            <>
              <Box display="flex" alignItems="center">
                {/* More Menu Icon */}
                <IconButton
                  aria-label="more"
                  aria-controls="more-menu"
                  aria-haspopup="true"
                  onClick={handleMoreClick}
                >
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  id="more-menu"
                  anchorEl={moreAnchorEl}
                  open={isMoreMenuOpen}
                  onClose={handleMoreClose}
                  getcontentanchorel={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  style={{ marginTop: "40px" }}
                >
                  <MenuItem onClick={handleMoreClose}>
                    <RouterLink to="/schedule" style={{ textDecoration: "none" }}>
                      <Typography color="textSecondary" variant="body2">
                        Schedule
                      </Typography>
                    </RouterLink>
                  </MenuItem>
                  <MenuItem onClick={handleMoreClose}>
                    <RouterLink to="/about" style={{ textDecoration: "none" }}>
                      <Typography color="textSecondary" variant="body2">
                        About Us
                      </Typography>
                    </RouterLink>
                  </MenuItem>
                </Menu>

                {/* Cart Icon */}
                {cartCount > 0 && (
                  <RouterLink to="/checkout" style={{ textDecoration: "none" }}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      ml={2} // Optional margin between More icon and cart
                    >
                      <LocalMallIcon className={classes.icon} />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.cartText}
                      >
                        {cartCount}
                      </Typography>
                    </Box>
                  </RouterLink>
                )}
              </Box>

            </>
          ) : (
            // Display Schedule, About Us, and Cart buttons on larger screens
            <Box display="flex" alignItems="center">
              <RouterLink to="/schedule" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.aboutButton}
                >
                  Schedule
                </Button>
              </RouterLink>

              <RouterLink to="/about" className={classes.linkDecoration}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.aboutButton}
                >
                  About Us
                </Button>
              </RouterLink>

              {cartCount > 0 && (
                <RouterLink to="/checkout" className={classes.linkDecoration}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    ml={theme.spacing(3)}
                  >
                    <LocalMallIcon className={classes.icon} />
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.cartText}
                    >
                      {cartCount}
                    </Typography>
                  </Box>
                </RouterLink>
              )}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(DHeader);
