import { gql, useQuery } from "@apollo/client";
import { restaurants } from "../apollo/server";

const RESTAURANTS = gql`
  ${restaurants}
`;

export default function useRestaurants() {
  const { data, refetch, networkStatus, loading, error } = useQuery(RESTAURANTS, {
    fetchPolicy: "network-only",
  });

  if (loading) ;
  if (error) ;
  if (data) ;
  return { data, refetch, networkStatus, loading, error };
}
