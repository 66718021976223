import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    marginHeader: {
      marginTop: "200px",
    },
    container: {
      backgroundColor: theme.palette.primary.light,
      padding: "2rem",
      borderRadius: "10px",
      width: "90%",
      margin: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    title: {
      color: theme.palette.common.black,
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "1rem",
    },
    subtitle: {
      color: theme.palette.common.black,
      fontWeight: "600",
      marginTop: theme.spacing(2),
    },
    paragraph: {
      color: theme.palette.common.black,
      lineHeight: "1.6",
      marginBottom: theme.spacing(2),
    },
    imageContainer: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
    residenceImage: {
      width: "100%", // Responsive width
      height: "auto", // Auto height based on the image aspect ratio
      borderRadius: "10px",
      objectFit: "cover", // Ensures images cover their area
    },
    mapImage: {
      width: "100%", // Make the map responsive
      maxWidth: "600px", // Set a maximum width to prevent overflow
      height: "auto", // Auto height to maintain aspect ratio
      borderRadius: "10px", // Consistent border radius
      margin: "0 auto", // Center the map
    },
    residenceName: {
      marginTop: theme.spacing(1),
      fontWeight: "bold",
      color: theme.palette.common.black,
    },
  }));
  
  export default useStyles;
  