import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    margin: 0, // Remove extra margin
    padding: 0, // Ensure no extra padding
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box', // Ensure padding and borders are included in width/height
    maxWidth: '100%', // Ensure container does not exceed its parent's width
    overflow: 'hidden', // Prevent content from overflowing
  },
  itemContainer: {
    backgroundSize: 'cover',               // Ensures the image covers the entire box
    backgroundPosition: 'center',          // Centers the image
    backgroundRepeat: 'no-repeat',         // Prevents repetition of the image
    borderRadius: 10,
    width: '100%',
    height: '200px',                       // Set the height of the container to ensure the image fills the space
    position: 'relative',                  // Allows positioning of children (like the add button)
    display: 'flex',                       // Flexbox to arrange content
    flexDirection: 'column',               // Content direction
    margin: 0,                             // Remove margin to ensure no additional spacing
    padding: 0,                            // Remove padding if it's causing overflow
    justifyContent: 'space-between',       // Space between content and buttons
    overflow: 'hidden',                    // Ensures content (especially background image) does not overflow
    color: theme.palette.common.white,     // Text color on the image
    boxSizing: 'border-box', // Ensure padding and borders are included in width/height
    maxWidth: '100%', // Ensure the container doesn't exceed its parent width
  },
  titleText: {
    paddingTop: "20px",
    paddingBottom: "20px",
    boxSizing: 'border-box', // Ensure padding is included in width/height calculation
    maxWidth: '100%', // Ensure the container doesn't exceed its parent width
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
  boldText: {
    fontWeight: 700,
    boxSizing: 'border-box',
    maxWidth: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
  itemDesc: {
    color: theme.palette.text.disabled,
    fontSize: "0.875rem",
    boxSizing: 'border-box',
    maxWidth: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
  discountText: {
    color: theme.palette.text.disabled,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: "0.875rem",
    textDecoration: "line-through",
    marginLeft: "5px",
    boxSizing: 'border-box',
    maxWidth: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
  contentContainer: {
    padding: theme.spacing(2),

    boxSizing: 'border-box', // Make sure padding and borders are included in width/height
    maxWidth: '100%', // Ensure the parent container doesn't allow overflow
    overflow: 'hidden',
  },
  textContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background for readability
    padding: '10px', // Add some padding inside the container
    boxSizing: 'border-box', // Make sure padding and borders are included in width/height
    maxWidth: '100%',
    overflow: 'hidden',
  },
  imageContainer: {
    width: "88px",
    height: "88px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    boxSizing: 'border-box',
    maxWidth: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
  addContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: "1px",
    borderRadius: "inherit",
    minWidth: "auto",
    position: "absolute",
    right: 0,
    bottom: 0,
    boxSizing: 'border-box',
    maxWidth: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyle;
