import { Box, Grid } from "@mui/material";
import React, { useContext } from "react";
import UserContext from "../../../context/User";
import CartView from "./CartView";
import EmptyView from "./EmptyView";

function RestaurantCart(props) {
  const { cart } = useContext(UserContext);
  return (
    <Grid
      item
      lg={3}
      xs={6}
      style={{
        background: "#fafafa",
        position: "sticky",
        top: "100px",
        padding: "0px 5px",
      }}
    >
      {!cart.length ? (
        <EmptyView showMessage={props.showMessage} />
      ) : (
        <CartView showMessage={props.showMessage} />
      )}
    </Grid>
  );
}

export default React.memo(RestaurantCart);
