import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Box,
  Menu,
  MenuItem,
  useTheme,
  IconButton,
  useMediaQuery,
  Divider,
  AppBar,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import FlashMessage from "../../../components/FlashMessage";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // "More" icon
import { Link as RouterLink, useLocation } from "react-router-dom";
import useStyle from "./styles";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import UserContext from "../../../context/User";


function LoginDesktopHeader({ title, showIcon, showCart = false }) {
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const { t } = useTranslation();
  const classes = useStyle();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Detect small screens
  const location = useLocation();
  const [mainError, setMainError] = useState({});
  const toggleSnackbar = useCallback(() => {
    setMainError({});
  }, []);
  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);
  const { cartCount } =
    useContext(UserContext);
  const handleMoreClick = (event) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };
  const isMoreMenuOpen = Boolean(moreAnchorEl);
  return (
    <>
      <FlashMessage
        open={Boolean(mainError.type)}
        severity={mainError.type}
        alertMessage={mainError.message}
        handleClose={toggleSnackbar}
        alive={mainError.alive || false}
      />
      <AppBar elevation={0} position="fixed">
        <Toolbar className={classes.toolbar}>
          <RouterLink
            to={location.pathname === "/checkout" ? "/restaurant-list" : "/"}
            className={classes.linkDecoration}
          >
            <Logo height={50} width={50} />

            <Typography
              variant="h6"
              color={theme.palette.common.black}
              className={classes.font700}
              style={{ marginLeft: "8px" }}
            >
              {title}
            </Typography>
          </RouterLink>
          <Box className={classes.flex}>
            {showIcon && (
              <>
                <RouterLink to={"/login"} className={classes.linkDecoration}>
                  <Button aria-controls="simple-menu" aria-haspopup="true">
                    <PersonIcon style={{ color: theme.palette.common.black }} />
                    <Typography
                      variant="button"
                      color="textSecondary"
                      className={`${classes.ml} ${classes.font700}`}
                    >
                      {t("loginBtn")}
                    </Typography>
                  </Button>
                </RouterLink>
              </>
            )}


            {isSmallScreen ? (
              <>
                <Box display="flex" alignItems="center">
                  {/* More Menu Icon */}
                  <IconButton
                    aria-label="more"
                    aria-controls="more-menu"
                    aria-haspopup="true"
                    onClick={handleMoreClick}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  <Menu
                    id="more-menu"
                    anchorEl={moreAnchorEl}
                    open={isMoreMenuOpen}
                    onClose={handleMoreClose}
                    getcontentanchorel={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    style={{ marginTop: "40px" }}
                  >
                    <MenuItem onClick={handleMoreClose}>
                      <RouterLink to="/schedule" style={{ textDecoration: "none", display: "block" }}>
                        <Typography color="textSecondary" variant="body2">
                          Schedule
                        </Typography>
                      </RouterLink>
                    </MenuItem>
                    <MenuItem onClick={handleMoreClose}>
                      <RouterLink to="/about" style={{ textDecoration: "none", display: "block" }}>
                        <Typography color="textSecondary" variant="body2">
                          About Us
                        </Typography>
                      </RouterLink>
                    </MenuItem>
                  </Menu>
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" alignItems="center">
                  <RouterLink to="/schedule" style={{ textDecoration: "none" }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.aboutButton}
                    >
                      Schedule
                    </Button>
                  </RouterLink>

                  <RouterLink to="/about" className={classes.linkDecoration}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.aboutButton}
                    >
                      About Us
                    </Button>
                  </RouterLink></Box></>
            )}




            {cartCount > 0 && (
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                onClick={() => {
                  showMessage({
                    type: "error",
                    message: "Please log in to proceed to checkout.",
                  });
                }}
                className={classes.flex}
              >
                <LocalMallIcon style={{ color: theme.palette.common.black }} />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.cartText}
                >
                  {cartCount}
                </Typography>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar></>
  );
}

export default React.memo(LoginDesktopHeader);
