// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5WeOkqml3rsw7nPyK_ocufQR1y-nYZY8",
  authDomain: "arboreal-drake-424604-a7.firebaseapp.com",
  projectId: "arboreal-drake-424604-a7",
  storageBucket: "arboreal-drake-424604-a7.appspot.com",
  messagingSenderId: "348790355017",
  appId: "1:348790355017:web:4aeb74e0dec69b81693b98",
  measurementId: "G-R4CMX8BYEK"
};

export const initialize = () => {
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  return messaging;
};

export const isFirebaseSupported = async () => {
  return await isSupported();
};
