import React, { useEffect, useContext } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useStyles from "./styles";
import { Header } from "../../components/Header";
import {LoginHeader} from "../../components/Header"
import UserContext from "../../context/User";
import Footer from "../../components/Footer/Footer";
import Analytics from "../../utils/analytics";

import ThunderbirdImage from "../../assets/images/thunderbird.png";
import MarineImage from "../../assets/images/marine.png";
import PonderosaCedarImage from "../../assets/images/ponderosa_cedar.png";
import PonderosaOakImage from "../../assets/images/ponderosa_oak.png";
import BrockCommonsImage from "../../assets/images/brock_commons.png";
import GageImage from "../../assets/images/gage.png";
import SturgeonImage from "../../assets/images/sturgeon.png";
import DeliveryMap from "../../assets/images/yumme_deliverylocations.png"

function AboutUs() {
    const theme = useTheme();
    const classes = useStyles(); // Use custom styles
    const {
        isLoggedIn,
    } = useContext(UserContext);

    useEffect(() => {
        const trackAnalytics = async () => {
            try {
                await Analytics.track(Analytics.events.NAVIGATE_TO_ABOUT_US);
            } catch (error) {
                console.error("Error tracking About Us navigation:", error);
            }
        };

        trackAnalytics();
    }, []);

    return (
        <Grid container className={classes.root}>

            {isLoggedIn ? <Header /> : <LoginHeader showIcon />}

            {/* Add paddingTop to ensure content is not hidden behind the header */}
            <Box className={classes.container} style={{ paddingTop: theme.spacing(8) }}>
                <Typography variant="h4" className={classes.title}>
                    About Yumme
                </Typography>
                <Box mt={theme.spacing(2)} />
                <Typography variant="body1" className={classes.paragraph}>
                    At <strong>Yumme</strong>, our mission is simple: to deliver delicious
                    and convenient $10 meals without the hassle of service charges or tips.
                    By consolidating deliveries to scheduled times and minimizing wasteful
                    packaging, we ensure that your meal is not only affordable but also
                    sustainable. In the coming months, be sure to check out our new takeout
                    cabinets, which will guarantee that your food is delivered safely and
                    conveniently to your pick-up spot.

                </Typography>
                <Typography variant="body1" className={classes.paragraph}>
                    Our restaurant partners include Handi Grill, 兰州拉面, Sports Illustrated, and 添玺Richmond. Head over to google map and check their stellar reviews.
                </Typography>
                <Box mt={theme.spacing(2)} />
                <Typography variant="h5" className={classes.subtitle}>
                    How Do We Work?
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                    1. Sign up for our text blast and get reminders 30 minutes before the
                    order window closes.
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                    2. Choose your meal(s) and pick-up location from our variety of
                    selections 90 minutes before delivery time.
                </Typography>
                <Typography variant="body2" className={classes.paragraph}>
                    3. Pick up your food at your designated spot and enjoy!
                </Typography>
                <Grid item xs={12}>
                    <Box className={classes.imageContainer}>
                        <img
                            src={DeliveryMap}
                            alt="Delivery Locations"
                            className={classes.mapImage} // Use the new mapImage class
                        />
                        <Typography variant="h6" className={classes.residenceName}>
                            Here's a map of our drop-off locations
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={4}>
                    {/* Residence 1: Thunderbird */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box className={classes.imageContainer}>
                            <img src={ThunderbirdImage} alt="Thunderbird" className={classes.residenceImage} />
                            <Typography variant="h6" className={classes.residenceName}>
                                Thunderbird
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Residence 2: Marine */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box className={classes.imageContainer}>
                            <img src={MarineImage} alt="Marine" className={classes.residenceImage} />
                            <Typography variant="h6" className={classes.residenceName}>
                                Marine
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Residence 3: Ponderosa Cedar House */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box className={classes.imageContainer}>
                            <img src={PonderosaCedarImage} alt="Ponderosa Cedar House" className={classes.residenceImage} />
                            <Typography variant="h6" className={classes.residenceName}>
                                Ponderosa Cedar House
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Residence 4: Ponderosa Oak House */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box className={classes.imageContainer}>
                            <img src={PonderosaOakImage} alt="Ponderosa Oak House" className={classes.residenceImage} />
                            <Typography variant="h6" className={classes.residenceName}>
                                Ponderosa Oak House
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Residence 5: Brock Commons North */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box className={classes.imageContainer}>
                            <img src={BrockCommonsImage} alt="Brock Commons North" className={classes.residenceImage} />
                            <Typography variant="h6" className={classes.residenceName}>
                                Brock Commons North
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Residence 6: Gage */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box className={classes.imageContainer}>
                            <img src={GageImage} alt="Gage" className={classes.residenceImage} />
                            <Typography variant="h6" className={classes.residenceName}>
                                Gage
                            </Typography>
                        </Box>
                    </Grid>
                    {/* Residence 7: Sturgeon */}
                    <Grid item xs={12} sm={6} md={4}>
                        <Box className={classes.imageContainer}>
                            <img src={SturgeonImage} alt="Sturgeon" className={classes.residenceImage} />
                            <Typography variant="h6" className={classes.residenceName}>
                                Sturgeon
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={theme.spacing(4)} />
            </Box>


        </Grid>
    );
}

export default AboutUs;
