import {jwtDecode} from "jwt-decode"; // This is the only correct way

import { gql, useMutation } from "@apollo/client";
import { useCallback, useContext, useState } from "react";
import { login } from "../apollo/server";
import UserContext from "../context/User";
import Analytics from "../utils/analytics";

const LOGIN = gql`
  ${login}
`;

function useRegistration() {
  const { setTokenAsync } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginButton, loginButtonSetter] = useState(null);
  const [Login] = useMutation(LOGIN, { onCompleted, onError });

  const toggleSnackbar = useCallback(() => {
    setLoginError("");
    setLogin(false);
  }, []);

  async function onCompleted({ login }) {
    try {
      if (login.inNewUser) {
        await Analytics.identify(
          {
            userId: login.userId,
            name: login.name,
            email: login.email,
          },
          login.userId
        );
        await Analytics.track(Analytics.events.USER_CREATED_ACCOUNT, {
          userId: login.userId,
          name: login.name,
          email: login.email,
        });
      } else {
        await Analytics.identify(
          {
            userId: login.userId,
            name: login.name,
            email: login.email,
          },
          login.userId
        );
        await Analytics.track(Analytics.events.USER_LOGGED_IN, {
          userId: login.userId,
          name: login.name,
          email: login.email,
        });
      }
      setLogin(true);
      await setTokenAsync(login.token);
    } catch (e) {
      setLoginError("Something went wrong");
      console.log("Error While saving token:", e);
    } finally {
      setLoading(false);
    }
  }

  function onError(errors) {
    setLoading(false);
    setLoginError(errors.message || "Invalid credentials!");
  }

  const authenticationFailure = useCallback((response) => {
    console.log("Authentication Failed: ", response);
    setLoading(false);
    loginButtonSetter(null);
    setLoginError("Something went wrong");
  }, []);

  const mutateLogin = useCallback(
    async (user) => {
      Login({
        variables: {
          ...user,
        },
      });
    },
    [Login]
  );

  const googleSuccess = useCallback(
    (response) => {
      try {
        // Decode the JWT token to extract user info
        const decodedToken = jwtDecode(response.credential); // Using the correct import

        // Extract user information from the decoded token
        const user = {
          phone: "",
          email: decodedToken.email,
          password: "",
          name: decodedToken.name,
          picture: decodedToken.picture,
          type: "google",
        };

        // Call the mutateLogin function to handle login logic
        mutateLogin(user);
      } catch (error) {
        console.error("Error decoding JWT token:", error);
        authenticationFailure(error);
      }
    },
    [mutateLogin, authenticationFailure]
  );

  return {
    loading,
    setLoading,
    loginButton,
    loginButtonSetter,
    mutateLogin,
    googleSuccess,
    authenticationFailure,
    setLoginError,
    loginError,
    login,
    toggleSnackbar,
  };
}

export default useRegistration;
