import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useContext } from "react";
import ConfigurationContext from "../../../context/Configuration";
import useStyles from "../ItemCard/styles";


// An array of food is passed in
function ItemList(props) {
  const theme = useTheme();
  const { date, time, foods } = props;
  const classes = useStyles();
  const configuration = useContext(ConfigurationContext);
  
  return (
    <Container 
      className={classes.cardContainer}
      style={{
        boxSizing: 'border-box',
        maxWidth: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h5"
        color="textSecondary"
        className={`${classes.titleText} ${classes.boldText}`}
        style={{
          boxSizing: 'border-box',
          maxWidth: '100%',
          margin: 10,
          padding: 0,
          overflow: 'hidden',
        }}
      >
        {time}
      </Typography>
      <Grid container spacing={2} style={{ margin: 10, padding: 0, boxSizing: 'border-box', maxWidth: '100%', overflow: 'hidden' }}>
        {foods.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={6}
            style={{
              boxSizing: 'border-box',
              maxWidth: '100%',
              overflow: 'hidden',
              padding: 10
            }}
          >
            <Paper
              elevation={1}
              square
              className={classes.itemContainer}
              style={{
                backgroundImage: `url(${item.image})`,  // Use the image as the background of the entire container
                backgroundSize: 'cover',                // This will make sure the image covers the entire area and maintains its aspect ratio
                backgroundPosition: 'center',           // Center the image
                backgroundRepeat: 'no-repeat',          // Prevent the image from repeating
                overflow: 'hidden',                    // Ensure the image does not overflow
                boxSizing: 'border-box',               // Ensure padding and borders are included in width/height
                maxWidth: '100%',
                aspectRatio: '4 / 3'
              }}
              onClick={() => {
                console.log('Item:', item);
                console.log('Date inside onClick:', date);
                console.log('Time inside onClick:', time);
                props.onPress({ ...item }, date, time);
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                className={classes.contentContainer}  // Add a container to hold the text and button
                style={{
                  boxSizing: 'border-box',
                  maxWidth: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box className={classes.textContainer} style={{
                  boxSizing: 'border-box',
                  maxWidth: '100%',
                  margin: 0,
                  padding: 0,
                  overflow: 'hidden',
                }}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.boldText}
                    style={{
                      boxSizing: 'border-box',
                      maxWidth: '100%',
                      margin: 0,
                      padding: 0,
                      overflow: 'hidden',
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography 
                    variant="caption" 
                    className={classes.itemDesc}
                    style={{
                      boxSizing: 'border-box',
                      maxWidth: '100%',
                      margin: 0,
                      padding: 0,
                      overflow: 'hidden',
                    }}
                  >
                    {item.description.length > 80
                      ? `${item.description.substring(0, 80)}...`
                      : item.description}
                  </Typography>
                  <Typography 
                  variant="body2" 
                  color="textSecondary"
                  style={{
                    boxSizing: 'border-box',
                    maxWidth: '100%',
                    margin: 0,
                    padding: 0,
                    overflow: 'hidden',
                  }}
                >
                  {`${configuration.currencySymbol} ${parseFloat(
                    item.variations[0].price
                  ).toFixed(2)}`}
                  {item.variations[0].discounted > 0 && (
                    <Typography
                      variant="caption"
                      className={classes.discountText}
                      style={{
                        boxSizing: 'border-box',
                        maxWidth: '100%',
                        margin: 0,
                        padding: 0,
                        overflow: 'hidden',
                      }}
                    >
                      {`${configuration.currencySymbol} ${parseFloat(
                        item.variations[0].price + item.variations[0].discounted
                      ).toFixed(2)}`}
                    </Typography>
                  )}
                </Typography>
                </Box>

              </Box>
              <Box
                className={classes.addContainer}
                style={{
                  boxSizing: 'border-box',
                  maxWidth: '100%',
                  margin: 0,
                  padding: 0,
                  overflow: 'hidden',
                }}
              >
                <Button>
                  <AddIcon style={{ color: theme.palette.common.white }} />
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default React.memo(ItemList);
