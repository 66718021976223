import * as React from "react";
import { useTheme } from "@mui/material";

function VisaIcon(props) {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={24}
      viewBox="0 0 32 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#1A1F71"
          d="M2 0h28a2 2 0 012 2v20a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2z"
        />
        <path
          fill={theme.palette.common.white}
          d="M19.688 8a5.21 5.21 0 011.815.332l-.341 1.59c-.868-.4-1.634-.372-1.912-.335-.56.073-.816.359-.823.627-.018.884 2.726.994 2.717 2.972-.006 1.559-1.338 2.565-3.376 2.565-.867-.009-1.704-.19-2.156-.398l.35-1.643c.452.21 1.018.489 1.992.474.559-.01 1.156-.23 1.161-.734.003-.329-.25-.564-1.005-.932-.736-.359-1.711-.96-1.698-2.04C16.422 9.019 17.772 8 19.688 8zm5.091.137h1.655L28 15.634h-1.794l-.234-1.12h-2.488l-.405 1.12h-2.035l2.91-6.947a.883.883 0 01.825-.55zm.284 2.026l-1.02 2.814h1.608l-.588-2.814zM13.944 8.137h1.94l-1.604 7.497h-1.94l1.604-7.497zm-2.869 0h2.037L9.97 15.634H7.92L6.374 9.65c-.094-.369-.175-.504-.46-.659-.467-.253-1.237-.49-1.914-.638l.046-.217h3.3c.42 0 .799.28.894.764l.817 4.338 2.018-5.102z"
        />
      </g>
    </svg>
  );
}

export default VisaIcon;
