/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";

import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import useStyle from "./styles";
import Analytics from "../../utils/analytics";

function PrivacyPolicy() {
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_PRIVACY_POLICY);
  }, []);
  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            className={classes.title}
          >
            Privacy Policy
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              className={classes.MV3}
            >
              This privacy notice describes how Yumme, a food delivery service,
              collects, uses, and shares your personal data, and your rights
              regarding this data. We are committed to protecting your
              information and ensuring transparency in our data practices.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              What information do we collect about you?
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Yumme collects data provided by users, data created during the use
              of our services, and data from other sources. This includes
              account information, identity verification documents, user
              content, location data, transaction information, and usage data.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Cookies and Third-Party Technologies
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Yumme uses cookies and similar technologies to enhance your
              experience, personalize content, and analyze traffic. We also use
              third-party analytics services to better understand how you use
              our services.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              How will we use the information we collect from you?
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We use your data to provide reliable and convenient food delivery
              services, ensure safety and security, offer customer support,
              conduct research, and for marketing purposes. Your data helps us
              personalize your experience, process payments, and communicate
              with you about your orders.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Data Sharing and Disclosure
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Yumme may share data with other users, business partners, service
              providers, or as required by law. We ensure that your data is
              protected and only shared when necessary to fulfill our services
              or comply with legal obligations.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Data Retention and Deletion
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Yumme retains user data for as long as necessary for the purposes
              described in this policy. Users can request account deletion
              through the Yumme app or website. We may retain certain data to
              comply with legal, safety, or fraud prevention requirements.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Choice and Transparency
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Yumme enables you to access, control, and manage your data through
              our app settings. You can adjust your privacy preferences, manage
              notifications, and request changes to your data. If you have
              questions or concerns, contact us at{" "}
              <Typography
                variant="caption"
                color="primary"
                className={classes.link}
              >
                {" privacy@yumme.ca"}
              </Typography>
              .
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Legal Information
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Yumme operates globally and complies with applicable legal
              frameworks for data transfers. We protect your data regardless of
              where it is processed, implementing security measures to ensure
              the safety of your information.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Updates to This Privacy Notice
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Yumme may update this notice from time to time. We will notify
              users of significant changes through our apps or other means. By
              using our services after updates, you consent to the updated
              privacy practices.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              For any questions regarding this privacy notice, please contact us
              at{" "}
              <Typography
                variant="caption"
                color="primary"
                className={classes.link}
              >
                {" privacy@yumme.ca"}
              </Typography>
              .
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default React.memo(PrivacyPolicy);