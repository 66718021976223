import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import ConfigurableValues from "../../config/constants";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import FlashMessage from "../../components/FlashMessage";
import useRegistration from "../../hooks/useRegistration";
import { LoginWrapper } from "../Wrapper";
import useStyles from "./styles";
import { useTranslation } from 'react-i18next';

function Login() {
  const { GOOGLE_CLIENT_ID } = ConfigurableValues();
  const { t } = useTranslation();
  const theme = useTheme();
  const [mainError, setMainError] = useState({});
  const classes = useStyles();
  const {
    googleSuccess,
    authenticationFailure,
    loading,
    setLoading,
    loginButton,
    loginButtonSetter,
    loginError,
  } = useRegistration();
  const location = useLocation();

  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);

  useEffect(() => {
    if (loginError) {
      showMessage({
        type: "error",
        message: loginError,
      });
    }
  }, [loginError, showMessage]);

  useEffect(() => {
    const initializeGoogleAPI = async () => {
      try {
        if (!GOOGLE_CLIENT_ID) {
          throw new Error("Google Client ID is missing");
        }
  
        if (window.google) {
          await window.google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
          });
          console.log("Google API initialized successfully");
        } else {
          throw new Error("Google API script not loaded");
        }
      } catch (error) {
        console.error("Error initializing Google API:", error);
        authenticationFailure(error); // Call your failure handler
      }
    };
  
    initializeGoogleAPI();
  }, [GOOGLE_CLIENT_ID]);
  
  const handleCredentialResponse = (response) => {
    try {
      const credential = response.credential;
      if (!credential) {
        throw new Error("No credential received");
      }
      console.log("Google JWT Token:", credential);
      googleSuccess(response); // Process the successful login
    } catch (error) {
      console.error("Error handling Google login response:", error);
      authenticationFailure(error); // Call your failure handler
    }
  };
  

  const callGoogle = useCallback(() => {
    if (!loading) {
      loginButtonSetter("GOOGLE");
      setLoading(true);
      // Trigger the One Tap UI if you'd like
      window.google.accounts.id.prompt(); 
    }
  }, [loading, loginButtonSetter, setLoading]);

  const toggleSnackbar = useCallback(() => {
    setMainError({});
  }, []);

  return (
    <LoginWrapper>
      <FlashMessage
        open={Boolean(mainError.type)}
        severity={mainError.type}
        alertMessage={mainError.message}
        handleClose={toggleSnackbar}
      />
      <Typography variant="h5" className={classes.font700}>
        {t('welcome')}
      </Typography>

      <Typography
        variant="caption"
        className={`${classes.caption} ${classes.fontSubHead} ${classes.font700}`}
      >
        {t('signUpOrLogin')}
      </Typography>

      {/* Google Sign-In Button */}
      {GOOGLE_CLIENT_ID ? (
        <Button
          variant="contained"
          fullWidth
          disableElevation
          className={`${classes.gButton} ${classes.btnBase}`}
          onClick={callGoogle}
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              <GoogleIcon />
            )
          }
        >
          {loading && loginButton === "GOOGLE" ? null : (
            <Typography
              variant="caption"
              color="textPrimary"
              align="center"
              className={`${classes.font700} ${classes.caption} ${classes.btnText}`}
            >
              {t('signInWithGoogle')}
            </Typography>
          )}
        </Button>
      ) : (
        <Typography variant="caption" color="textSecondary">
          Loading Google Sign-In...
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            alignSelf: "center",
            justifyContent: "space-around",
            alignItems: "center",
            margin: theme.spacing(2, 0),
          }}
        >
          <div className={classes.line}></div>
          <Typography
            variant="caption"
            className={`${classes.fontGrey} ${classes.caption} ${classes.font700}`}
          >
            {t('or')}
          </Typography>
          <div className={classes.line}></div>
        </div>
      </Box>

      <RouterLink
        to="/new-login"
        state={{ from: location.state?.from }}
        style={{ textDecoration: "none" }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          disableRipple
          disableFocusRipple
          disableTouchRipple
          className={`${classes.btnBase} ${classes.customBtn}`}
        >
          <Typography
            variant="caption"
            color="secondary"
            className={`${classes.font700} ${classes.caption}`}
          >
            {t('continueWithEmail')}
          </Typography>
        </Button>
      </RouterLink>

      <Box
        display="flex"
        sx={{ justifyContent: "center", alignItems: "center" }}
        flexWrap="wrap"
        mt={theme.spacing(3)}
      >
        <Typography
          style={{
            width: "80%",
          }}
          variant="caption"
          className={`${classes.fontGrey} ${classes.caption}`}
        >
          {t('bySigningUp')}
          <RouterLink to="/terms" style={{ textDecoration: "none" }}>
            <Typography
              variant="caption"
              color="primary"
              className={`${classes.font700} ${classes.caption}`}
            >
              {t('terms')}
            </Typography>
          </RouterLink>
          {t('and')}
          <RouterLink to="/privacy" style={{ textDecoration: "none" }}>
            <Typography
              variant="caption"
              color="primary"
              className={`${classes.font700} ${classes.caption}`}
            >
              {t('privacyPolicy')}
            </Typography>
          </RouterLink>
        </Typography>
      </Box>
    </LoginWrapper>
  );
}

export default Login;
