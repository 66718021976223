/* eslint-disable react-hooks/exhaustive-deps */
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import {
  myOrders,
  orderStatusChanged,
  profile,
  saveNotificationTokenWeb,
} from "../apollo/server";
import { useRestaurants } from "../hooks";

const PROFILE = gql`
  ${profile}
`;

const ORDERS = gql`
  ${myOrders}
`;
const SUBSCRIPTION_ORDERS = gql`
  ${orderStatusChanged}
`; // rename this 'subscriptionOrders' when user context is complete

const SAVE_NOTIFICATION_TOKEN_WEB = gql`
  ${saveNotificationTokenWeb}
`;

const UserContext = React.createContext({});

export const UserProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const client = useApolloClient();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [cart, setCart] = useState([]); // use initial state of cart here
  const [restaurant, setRestaurant] = useState(null);
  const [saveNotificationToken] = useMutation(SAVE_NOTIFICATION_TOKEN_WEB, {
    onCompleted,
    onError,
  });
  const [
    fetchProfile,
    {
      called: calledProfile,
      loading: loadingProfile,
      error: errorProfile,
      data: dataProfile,
    },
  ] = useLazyQuery(PROFILE, {
    fetchPolicy: "network-only",
    onCompleted,
    onError,
  });

  const [
    fetchOrders,
    {
      called: calledOrders,
      loading: loadingOrders,
      error: errorOrders,
      data: dataOrders,
      networkStatus: networkStatusOrders,
      fetchMore: fetchMoreOrders,
      subscribeToMore: subscribeToMoreOrders,
    },
  ] = useLazyQuery(ORDERS, {
    fetchPolicy: "network-only",
    onCompleted,
    onError,
  });

  const { data } = useRestaurants(); // Fetch all restaurants
  const restaurants = data?.restaurants ?? []; // Ensure restaurants is always an array


  const cartCount = cart.reduce((acc, item) => acc + item.quantity, 0);

  useEffect(() => {
    if (restaurants.length > 0) didFocus();
  }, [restaurants, cartCount]);


  useEffect(() => {
    if (restaurants.length > 0) didFocus();
  }, [restaurants, cartCount]);

  const didFocus = async () => {
    // Flatten foods, addons, and options for each restaurant and map them based on restaurantId in cart
    const allFoods = restaurants.reduce((acc, restaurant) => {
      const foods = restaurant.categories.map((c) => c.foods.flat()).flat();
      acc[restaurant._id] = {
        foods,
        addons: restaurant.addons,
        options: restaurant.options,
      };
      return acc;
    }, {});

    try {
      if (cart && cartCount) {
        const transformCart = cart.map((cartItem) => {
          const { restaurantId } = cartItem;
          const { foods, addons, options } = allFoods[restaurantId] || {};

          if (!foods) return null;

          const foodItem = foods.find((food) => food._id === cartItem._id);
          if (!foodItem) return null;

          const variationItem = foodItem.variations.find(
            (variation) => variation._id === cartItem.variation._id
          );
          if (!variationItem) return null;

          const foodItemTitle = `${foodItem.title}${
            variationItem.title ? `(${variationItem.title})` : ""
          }`;
          let foodItemPrice = variationItem.price;
          let optionTitles = [];

          if (cartItem.addons) {
            cartItem.addons.forEach((addon) => {
              const cartAddon = addons.find((add) => add._id === addon._id);
              if (!cartAddon) return null;
              addon.options.forEach((option) => {
                const cartOption = options.find(
                  (opt) => opt._id === option._id
                );
                if (!cartOption) return null;
                foodItemPrice += cartOption.price;
                optionTitles.push(cartOption.title);
              });
            });
          }

          return {
            ...cartItem,
            title: foodItemTitle,
            foodTitle: foodItem.title,
            variationTitle: variationItem.title,
            optionTitles,
            price: foodItemPrice.toFixed(2),
          };
        });

        const updatedItems = transformCart.filter((item) => item);
        if (updatedItems.length === 0) await clearCart();
        await updateCart(updatedItems);

        if (transformCart.length !== updatedItems.length) {
          props.showMessage({
            type: "warning",
            message: "warningText",
          });
        }
      }
    } catch (e) {
      props.showMessage({
        type: "error",
        message: e.message,
      });
    } 
  };



















  useEffect(() => {
    if (!token) {
      setIsLoading(false);
      return;
    }
    let isSubscribed = true;
    (async () => {
      isSubscribed && setIsLoading(true);
      isSubscribed && (await fetchProfile());
      isSubscribed && (await fetchOrders());
      isSubscribed && setIsLoading(false);
    })();
    return () => {
      isSubscribed = false;
    };
  }, [token]);

  useEffect(() => {
    if (!dataProfile) return;
    subscribeOrders();
  }, [dataProfile]);

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      const restaurant = localStorage.getItem("restaurant");
      const cart = localStorage.getItem("cartItems");
      isSubscribed && setRestaurant(restaurant || null);
      isSubscribed && setCart(cart ? JSON.parse(cart) : []);
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  function onCompleted({ profile, orders, saveNotificationTokenWeb }) {
    
    if (profile) {
      updateNotificationToken();
    }
  }

  function onError(error) {
    console.log("error", error.message);
  }

  const setTokenAsync = async (tokenReq, cb = () => {}) => {
    setToken(tokenReq);
    localStorage.setItem("token", tokenReq);
    cb();
  };

  const logout = async () => {
    try {
      localStorage.removeItem("token");
      setToken(null);
      await client.resetStore();
    } catch (error) {
      console.log("error on logout", error);
    }
  };

  const subscribeOrders = () => {
  
    try {
      const unsubscribeOrders = subscribeToMoreOrders({
        document: SUBSCRIPTION_ORDERS,
        variables: { userId: dataProfile.profile._id },
        updateQuery: (prev, { subscriptionData }) => {
      
          if (!subscriptionData.data) return prev;
          const { _id } = subscriptionData.data.orderStatusChanged.order;
          if (subscriptionData.data.orderStatusChanged.origin === "new") {
            if (prev?.orders?.findIndex((o) => o._id === _id) > -1) return prev;
            return {
              orders: [
                subscriptionData.data.orderStatusChanged.order,
                ...prev.orders,
              ],
            };
          } else {
            const { orders } = prev;
            let newList = [...orders];
            const orderIndex = newList.findIndex((o) => o._id === _id);
            if (orderIndex > -1) {
              newList[orderIndex] =
                subscriptionData.data.orderStatusChanged.order;
            }
            return {
              orders: [...newList],
            };
          }
        },
      });
      client.onResetStore(unsubscribeOrders);
    } catch (error) {
      console.log("error subscribing order", error.message);
    }
  };

  const fetchMoreOrdersFunc = () => {
    if (networkStatusOrders === 7) {
      fetchMoreOrders({
        variables: { offset: dataOrders.orders.length + 1 },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // Don't do anything if there weren't any new items
          if (!fetchMoreResult || fetchMoreResult.orders.length === 0) {
            return previousResult;
          }
          return {
            // Append the new feed results to the old one
            orders: previousResult.orders.concat(fetchMoreResult.orders),
          };
        },
      });
    }
  };

  const clearCart = () => {
    setCart([]);
    setRestaurant(null);
    localStorage.removeItem("cartItems");
    localStorage.removeItem("restaurant");
  };

  const addQuantity = async (key, quantity = 1) => {
    const cartIndex = cart.findIndex((c) => c.key === key);
    cart[cartIndex].quantity += quantity;
    setCart([...cart]);
    localStorage.setItem("cartItems", JSON.stringify([...cart]));
  };

  const deleteItem = async (key) => {
    console.log('deleteItem ran');
    const cartIndex = cart.findIndex((c) => c.key === key);
    if (cartIndex > -1) {
      console.log("Item to be deleted found in cart")
      cart.splice(cartIndex, 1);
      const items = [...cart.filter((c) => c.quantity > 0)];
      setCart(items);
      localStorage.setItem("cartItems", JSON.stringify(items));
    }
  };

  const removeQuantity = async (key) => {
    // Find the index of the item in the cart
    const cartIndex = cart.findIndex((c) => c.key === key);
    
    // Decrease the item's quantity
    cart[cartIndex].quantity -= 1;
  
    // If the quantity is greater than 0, update the cart; otherwise, remove it
    if (cart[cartIndex].quantity > 0) {
      console.log("quantity greater than 0")
      const items = [...cart];
      setCart(items);
      localStorage.setItem("cartItems", JSON.stringify(items));
    } else {
      console.log("quantity = 0")
      // If quantity is 0, remove the item from the cart
      const items = [...cart.filter((c) => c.key !== key)];
      console.log(items);
      setCart(items);
      localStorage.setItem("cartItems", JSON.stringify(items));
  
      // If the cart is empty after removing the item, set restaurant to null
      // if (items.length === 0) {
      //   setRestaurant(null);
      // }
    }
  };
  

  const checkItemCart = (itemId, date, time) => {
    const cartIndex = cart.findIndex((c) => (c._id === itemId && c.date === date && c.time === time));
    if (cartIndex < 0) {
      return {
        exist: false,
        quantity: 0,
      };
    } else {
      return {
        exist: true,
        quantity: cart[cartIndex].quantity,
        key: cart[cartIndex].key,
      };
    }
  };

  const numberOfCartItems = () => {
    return cart
      .map((c) => c.quantity)
      .reduce(function (a, b) {
        return a + b;
      }, 0);
  };

  const addCartItem = async (
    _id,
    restaurantId,
    date,
    time,
    variation,
    quantity = 1,
    addons = [],
    clearFlag,
    specialInstructions = ""
  ) => {
    const cartItems = clearFlag ? [] : cart;
    cartItems.push({
      key: v4(),
      _id,
      restaurantId,
      date,
      time,
      quantity: quantity,
      variation: {
        _id: variation,
      },
      addons,
      specialInstructions,
    });

    localStorage.setItem("cartItems", JSON.stringify([...cartItems]));
    setCart([...cartItems]);
  };

  const updateCart = async (cart) => {
    setCart(cart);
    localStorage.setItem("cartItems", JSON.stringify(cart));
  };

  const setCartRestaurant = async (id) => {
    setRestaurant(id);
    localStorage.setItem("restaurant", id);
  };

  const updateNotificationToken = () => {
   
    const token = localStorage.getItem("messaging-token");
    if (token) {
     
      saveNotificationToken({ variables: { token } });
    }
  };

  

  return (
    <UserContext.Provider
      value={{
        isLoggedIn: !!token,
        loadingProfile: loadingProfile && calledProfile,
        errorProfile,
        profile:
          dataProfile && dataProfile.profile ? dataProfile.profile : null,
        setTokenAsync,
        logout,
        loadingOrders: loadingOrders && calledOrders,
        errorOrders,
        orders: dataOrders && dataOrders.orders ? dataOrders.orders : [],
        fetchOrders,
        fetchMoreOrdersFunc,
        networkStatusOrders,
        cart,
        cartCount: numberOfCartItems(),
        clearCart,
        updateCart,
        addQuantity,
        removeQuantity,
        addCartItem,
        checkItemCart,
        deleteItem,
        restaurant,
        setCartRestaurant,
        isLoading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
export default UserContext;
